import { Chip } from '@sqs/rosetta-elements';
import { InfoCircle } from '@sqs/rosetta-icons';
import React, { CSSProperties } from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { t } from '../../i18n';
import { OrgUserStatus } from '../../models/SqspUser';

interface UserStatusChipProps {
  readonly status: OrgUserStatus;
}

export const UserStatusChip = ({ status }: UserStatusChipProps) => {
  const { isMobile } = usePlatformBreakpoint();
  const sx: CSSProperties = {};
  if (isMobile) {
    sx.marginBottom = '-4px';
  }
  switch (status) {
    case 'ACCEPTED':
      return (
        <Chip
          sx={sx}
          usage={isMobile ? 'badge' : 'default'}
          label={t("Active", null, { project: 'enterprise-dashboard' })}
          status="success" />);


    case 'CANCELED':
      return (
        <Chip
          sx={sx}
          usage={isMobile ? 'badge' : 'default'}
          label={t("Inactive", null, { project: 'enterprise-dashboard' })} />);


    case 'SENT':
      sx.marginBottom = '-4px';
      sx.marginTop = '3px';
      return (
        <Chip
          sx={sx}
          usage={isMobile ? 'badge' : 'default'}
          label={t("Pending invitation", null, { project: 'enterprise-dashboard' })}
          status="info"
          glyph={<InfoCircle />} />);


    case 'EXPIRED':
      return (
        <Chip
          sx={sx}
          usage={isMobile ? 'badge' : 'default'}
          label={t("Expired", null, { project: 'enterprise-dashboard' })}
          status="error" />);


    default:
      return (
        <Chip
          sx={sx}
          usage={isMobile ? 'badge' : 'default'}
          label={t("Unknown", null, { project: 'enterprise-dashboard' })} />);


  }
};
import { Banner } from '@sqs/rosetta-compositions';
import { Chip, Tabs, TabsOptionProps } from '@sqs/rosetta-elements';
import { Search } from '@sqs/rosetta-icons';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FEATURE_FLAGS } from '../../const/feature-flags';
import { T, t } from '../../i18n';
import { selectEnterprise } from '../../stores/currentUser';
import { selectAll as selectAllInvitedOrgUsers } from '../../stores/invitedOrgUsers';
import { RootState } from '../../stores/rootReducer';
import { useAppSelector } from '../../stores/store';
import { enterpriseHasSsoProvider, ssoProviderMap } from '../../utils/enterpriseUtils';
import { CreateUser } from '../CreateUser/CreateUser';
import { FeatureFlagContext } from '../FeatureFlagProvider';
import { PageHeader } from '../PageHeader';
import { UsersListResults } from './UsersListResults';

interface UsersProps {
  readonly showInvitedUsers?: boolean;
}

export const Users = ({ showInvitedUsers }: UsersProps) => {
  const { fontSizes, space } = useTheme();
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>(showInvitedUsers ? '/users/invited' : '/users');
  const [showSsoBanner, setShowSsoBanner] = useState(true);
  const { flagEnabled } = useContext(FeatureFlagContext);

  // TODO: Remove with AE-634
  const filterUsersByInviteStatus = flagEnabled(FEATURE_FLAGS.ENTERPRISE_SEPARATE_INVITED_USER);

  const { expiredInvites, enterprise } = useAppSelector((state: RootState) => {
    return {
      enterprise: selectEnterprise(state.currentUser),
      expiredInvites: selectAllInvitedOrgUsers(state.invitedOrgUsers).filter((user) => user.status === 'EXPIRED').
      length
    };
  });

  const hasSsoProvider = enterpriseHasSsoProvider(enterprise);
  const showFilterUsersByInviteStatus = !hasSsoProvider && filterUsersByInviteStatus;

  const mobileSearchButton = () => {
    return (
      <Button.Tertiary key="show-search" type="button" onClick={() => setShowSearch(!showSearch)}>
        <Search />
      </Button.Tertiary>);

  };

  const tabOptions: TabsOptionProps[] = [
  {
    value: '/users',
    label: t("Active", {}, { project: 'enterprise-dashboard' }),
    sx: {
      marginRight: space[5],
      fontSize: fontSizes[4]
    }
  },
  {
    value: '/users/invited',
    label: (color) => {
      return (
        <Flex color={color}>
            <Text.Body as={'div'} m={0} color={color} fontWeight={500}>
              <T project="enterprise-dashboard">{"Invited"}</T>
            </Text.Body>
            {expiredInvites > 0 ?
          <Chip usage="badge" sx={{ marginLeft: '8px' }} status="error" label={expiredInvites} /> :

          <></>
          }
          </Flex>);

    },
    sx: {
      marginLeft: space[5],
      marginRight: space[5],
      fontSize: fontSizes[4]
    }
  }];


  const changeTab = (v: string) => {
    setSelectedTab(v);
    navigate(v);
  };

  return (
    <Box position="relative">
      {hasSsoProvider && showSsoBanner &&
      <Banner
        mt={7}
        layout="row"
        title={t("Your organization is using {provider}",

        { provider: ssoProviderMap(enterprise?.loginMethod) }, {
          project: 'enterprise-dashboard' })
        }
        body={t("Users can only be invited through your current SSO provider.",

        {}, {
          project: 'enterprise-dashboard' })
        }
        buttons={[
        <Button.Tertiary key="dismiss" onClick={() => setShowSsoBanner(false)}>
              {t("Dismiss", {}, { project: 'enterprise-dashboard' })}
            </Button.Tertiary>]
        } />

      }
      <PageHeader
        actionButton={<CreateUser disabled={hasSsoProvider} />}
        mobileActions={[mobileSearchButton(), <CreateUser key="create-user-btn" disabled={hasSsoProvider} />]}
        title={t("Users", {}, { project: 'enterprise-dashboard' })} />

      {showFilterUsersByInviteStatus &&
      <Box mb={7}>
          <Tabs
          htmlAttributes={{ css: { '&:before': { height: 0 } } }}
          options={tabOptions}
          value={selectedTab}
          onChange={changeTab} />

        </Box>
      }
      <UsersListResults showSearch={showSearch} invitedUsersOnly={showInvitedUsers} />
    </Box>);

};
import { createEntityAdapter, createSlice, EntityState, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { CreateOrgUserData, createSqspOrgUser, listInvitedOrgUsers } from '../../apis/EnterpriseApiV2';
import { PAGINATION_LIMIT_MIN } from '../../const/pagination';
import { t } from '../../i18n';
import { PageInfo, PageInfoQueryWithSearch } from '../../models/PaginatedResponse';
import { OrgUser } from '../../models/SqspUser';
import { reduxErrorHandler } from '../../utils/reduxError';
import { sendSuccessMessage } from '../messages';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';

export type InvitedOrgUsersState = {
  isLoading: boolean;
  error: null | string | Record<string, string>;
  pageInfo: PageInfo;
} & EntityState<OrgUser>;

const invitedOrgUsersAdaptor = createEntityAdapter<OrgUser>();

export const invitedOrgUsersSlice = createSlice<
  InvitedOrgUsersState,
  SliceCaseReducers<InvitedOrgUsersState>,
  'invitedOrgUsers'>(
  {
    name: 'invitedOrgUsers',
    initialState: invitedOrgUsersAdaptor.getInitialState({
      isLoading: true,
      error: null,
      pageInfo: {
        total: 0,
        limit: PAGINATION_LIMIT_MIN,
        offset: 0
      }
    }),
    reducers: {
      invitedOrgUserRequested(state) {
        state.isLoading = true;
        state.error = null;
      },
      invitedOrgUsersRequested(state) {
        state.isLoading = true;
        state.error = null;
      },
      invitedOrgUserReceived(state, action: PayloadAction<OrgUser>) {
        invitedOrgUsersAdaptor.upsertOne(state, action.payload);
        state.isLoading = false;
        state.error = null;
      },
      invitedOrgUsersReceived(state, action: PayloadAction<OrgUser[]>) {
        invitedOrgUsersAdaptor.setAll(state, action.payload);
        state.isLoading = false;
        state.error = null;
      },
      errorReceived(state, action: PayloadAction<string | Record<string, string>>) {
        state.error = action.payload;
        state.isLoading = false;
      },
      updatePageInfo(state, action: PayloadAction<PageInfo>) {
        state.pageInfo = action.payload;
        state.isLoading = false;
      }
    }
  });

// Selectors
export const { selectAll, selectById, selectTotal } = invitedOrgUsersAdaptor.getSelectors();

export const filterSingleUser = (state: RootState, id: number) => {
  const foundUser = selectAll(state.invitedOrgUsers).filter((invitedOrgUser: OrgUser) => invitedOrgUser.id === id);
  if (foundUser.length) {
    return foundUser[0];
  }
  return null;
};

export const { reducer: invitedOrgUsersReducer } = invitedOrgUsersSlice;

export const {
  invitedOrgUserRequested,
  invitedOrgUsersRequested,
  invitedOrgUserReceived,
  invitedOrgUsersReceived,
  errorReceived,
  updatePageInfo
} = invitedOrgUsersSlice.actions;

export const fetchInvitedOrgUsers = (enterpriseId: number, options?: PageInfoQueryWithSearch): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(invitedOrgUsersRequested(undefined));
      const orgUsers = await listInvitedOrgUsers(enterpriseId, options);
      dispatch(invitedOrgUsersReceived(orgUsers.data));
      dispatch(updatePageInfo(orgUsers.pageInfo));
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("Not authorized to view users",
        null, { project: 'enterprise-dashboard' })
      );
    }
  };
};

export const createInvitedOrgUser = (enterpriseId: number, userData: CreateOrgUserData): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(invitedOrgUserRequested(undefined));
      const orgUser = await createSqspOrgUser(enterpriseId, userData);
      dispatch(
        sendSuccessMessage(t("An invitation was emailed to {user}",
        { user: userData.name }, { project: 'enterprise-dashboard' })
        )
      );
      dispatch(invitedOrgUserReceived(orgUser));
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("Not authorized to create new org user",
        null, { project: 'enterprise-dashboard' })
      );
    }
  };
};
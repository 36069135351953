import { FEATURE_FLAGS } from '../../const/feature-flags';
import { FeatureFlagContext } from '../FeatureFlagProvider';
import { PageHeader } from '../PageHeader';
import { t } from '../../i18n';
import React, { useContext, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tabs, TabsOptionProps } from '@sqs/rosetta-elements';
import { useAppSelector } from '../../stores/store';
import { RootState } from '../../stores/rootReducer';

export const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { flagEnabled } = useContext(FeatureFlagContext);

  let currentTabFromLocation = location.pathname;
  if (location.pathname === '/settings') {
    currentTabFromLocation = '/settings/organization';
  }
  const { isLoading } = useAppSelector((state: RootState) => ({
    isLoading: state.currentUser.isLoading
  }));
  const canUserUseSso = flagEnabled(FEATURE_FLAGS.ENTERPRISE_SHOW_SSO_IN_DASHBOARD);

  const [currentTab, setCurrentTab] = useState(currentTabFromLocation);

  const tabOptions: TabsOptionProps[] = [
  {
    label: t("Organization", null, { project: 'enterprise-dashboard' }),
    value: '/settings/organization',
    sx: { marginRight: '60px' }
  },
  {
    label: t("API Key", null, { project: 'enterprise-dashboard' }),
    value: '/settings/apiKey',
    sx: { marginRight: '60px' }
  }];

  if (canUserUseSso) {
    // tabOptions;tabOptions
    tabOptions.splice(1, 0, {
      label: t("SSO", null, { project: 'enterprise-dashboard' }),
      value: '/settings/sso',
      sx: { marginRight: '60px' }
    });
  }

  return (
    <div aria-live="polite" aria-busy={isLoading}>
      <PageHeader title={t("Settings", {}, { project: 'enterprise-dashboard' })} />
      <Tabs
        htmlAttributes={{ css: { '&:before': { height: 0 } } }}
        options={tabOptions}
        value={currentTab}
        onChange={(value: string) => {
          setCurrentTab(value);
          navigate(value);
        }} />

      <Outlet />
    </div>);

};
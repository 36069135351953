import { createEntityAdapter, createSlice, EntityState, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSqspOrgUser, fetchOrgUserData, listOrgUsers, CreateOrgUserData } from '../../apis/EnterpriseApiV2';
import { t } from '../../i18n';
import { INITIAL_LIMIT, PAGINATION_LIMIT_MIN } from '../../const/pagination';
import { PageInfo, PageInfoQueryWithSearch } from '../../models/PaginatedResponse';
import { OrgUser } from '../../models/SqspUser';
import { reduxErrorHandler } from '../../utils/reduxError';
import { sendSuccessMessage } from '../messages';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';

export type OrgUsersState = {
  isLoading: boolean;
  error: null | string | Record<string, string>;
  pageInfo: PageInfo;
} & EntityState<OrgUser>;

const orgUsersAdaptor = createEntityAdapter<OrgUser>();

export const orgUsersSlice = createSlice<OrgUsersState, SliceCaseReducers<OrgUsersState>, 'orgUsers'>({
  name: 'orgUsers',
  initialState: orgUsersAdaptor.getInitialState({
    isLoading: true,
    error: null,
    pageInfo: {
      total: 0,
      limit: PAGINATION_LIMIT_MIN,
      offset: 0
    }
  }),
  reducers: {
    orgUserRequested(state) {
      state.isLoading = true;
      state.error = null;
    },
    orgUsersRequested(state) {
      state.isLoading = true;
      state.error = null;
    },
    orgUserReceived(state, action: PayloadAction<OrgUser>) {
      orgUsersAdaptor.upsertOne(state, action.payload);
      state.isLoading = false;
      state.error = null;
    },
    orgUsersReceived(state, action: PayloadAction<OrgUser[]>) {
      orgUsersAdaptor.setAll(state, action.payload);
      state.isLoading = false;
      state.error = null;
    },
    appendOrgUsers(state, action: PayloadAction<OrgUser[]>) {
      orgUsersAdaptor.upsertMany(state, action.payload);
      state.isLoading = false;
      state.error = null;
    },
    errorReceived(state, action: PayloadAction<string | Record<string, string>>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    updatePageInfo(state, action: PayloadAction<PageInfo>) {
      state.pageInfo = action.payload;
      state.isLoading = false;
    }
  }
});

// Selectors
export const { selectAll, selectById, selectTotal } = orgUsersAdaptor.getSelectors();

export const filterSingleUser = (state: RootState, id: number) => {
  const foundUser = selectAll(state.orgUsers).filter((orgUser: OrgUser) => orgUser.id === id);
  if (foundUser.length) {
    return foundUser[0];
  }
  return null;
};

export const { reducer: orgUsersReducer } = orgUsersSlice;

export const {
  orgUserRequested,
  orgUsersRequested,
  orgUserReceived,
  orgUsersReceived,
  appendOrgUsers,
  errorReceived,
  updatePageInfo
} = orgUsersSlice.actions;

export const fetchBackgroundOrgUsers = (enterpriseId: number, options?: Partial<PageInfoQueryWithSearch>): AppThunk => {
  return async (dispatch) => {
    try {
      if (options?.offset && options.offset >= INITIAL_LIMIT) {
        const orgUsersResponse = await listOrgUsers(enterpriseId, options);
        dispatch(appendOrgUsers(orgUsersResponse.data));
        dispatch(updatePageInfo(orgUsersResponse.pageInfo));

        if (options.offset + orgUsersResponse.data.length < orgUsersResponse.pageInfo.total) {
          dispatch(
            fetchBackgroundOrgUsers(enterpriseId, {
              ...options,
              offset: options.offset + orgUsersResponse.data.length
            })
          );
        }
      }
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("Not authorized to view users",
        null, { project: 'enterprise-dashboard' })
      );
    }
  };
};

export const fetchOrgUsers = (enterpriseId: number, options?: PageInfoQueryWithSearch): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(orgUsersRequested(undefined));
      const orgUsers = await listOrgUsers(enterpriseId, options);
      dispatch(orgUsersReceived(orgUsers.data));
      dispatch(updatePageInfo(orgUsers.pageInfo));

      if (orgUsers.data.length < orgUsers.pageInfo.total) {
        dispatch(fetchBackgroundOrgUsers(enterpriseId, { ...options, offset: orgUsers.data.length }));
      }
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("Not authorized to view users",
        null, { project: 'enterprise-dashboard' })
      );
    }
  };
};

export const fetchOrgUser = (enterpriseId: number, userId: number): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(orgUserRequested(undefined));
      const orgUser = await fetchOrgUserData(enterpriseId, userId);
      dispatch(orgUserReceived(orgUser));
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("Not authorized to fetch user",
        null, { project: 'enterprise-dashboard' })
      );
    }
  };
};

// TODO: Remove with AE-634
export const createOrgUser = (enterpriseId: number, userData: CreateOrgUserData): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(orgUserRequested(undefined));
      const orgUser = await createSqspOrgUser(enterpriseId, userData);
      dispatch(
        sendSuccessMessage(t("An invitation was emailed to {user}",
        { user: userData.name }, { project: 'enterprise-dashboard' })
        )
      );
      dispatch(orgUserReceived(orgUser));
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("Not authorized to create new org user",
        null, { project: 'enterprise-dashboard' })
      );
    }
  };
};
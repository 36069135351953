import * as Sentry from '@sentry/browser';
import { Stack } from '@sqs/rosetta-elements';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { useForm } from 'react-hook-form';
import { updateEnterpriseInformation } from '../../apis/EnterpriseApi';
import { t, T } from '../../i18n';
import { Enterprise } from '../../models/Enterprise';
import { fetchCurrentUser } from '../../stores/currentUser';
import { sendErrorMessage, sendSuccessMessage } from '../../stores/messages';
import { useAppDispatch } from '../../stores/store';
import { AcuityTextFieldController } from '../common/AcuityTextFieldController';
import { GenericModal } from '../common/GenericModal';
import { ModalTitle } from '../common/ModalTitle';

interface OrganizationNameModalProps {
  readonly closeModal: () => void;
  readonly enterprise: Enterprise;
}

interface OrganizationNameModalForm {
  orgName: string;
}

export const OrganizationNameModal = ({ closeModal, enterprise }: OrganizationNameModalProps) => {
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { isValid },
    handleSubmit
  } = useForm<OrganizationNameModalForm>({ defaultValues: { orgName: enterprise.name } });

  const submitForm = handleSubmit((formData) => {
    updateEnterpriseInformation(enterprise.id, formData.orgName).
    then(() => {
      dispatch(fetchCurrentUser());
      dispatch(
        sendSuccessMessage(t("Your organization name has been updated.", null, { project: 'enterprise-dashboard' }))
      );
    }).
    catch((e) => {
      Sentry.captureEvent(e as Error);
      dispatch(
        sendErrorMessage(t("There was an error changing your organization's name.",
        null, {
          project: 'enterprise-dashboard' })

        )
      );
    }).
    finally(closeModal);
  });

  return (
    <GenericModal
      closeModal={closeModal}
      modalActions={
      <Stack direction="row" justifyContent="space-between" space={1}>
          <Button.Tertiary size="medium" onClick={() => closeModal()}>
            Cancel
          </Button.Tertiary>
          <Button.Primary size="medium" type="submit" onClick={submitForm}>
            Update
          </Button.Primary>
        </Stack>
      }>

      <Box mb={6}>
        <ModalTitle>
          <T project="enterprise-dashboard">{"Edit Name"}</T>
        </ModalTitle>

        <Text.Body as="label" fontWeight={500} mb={0}>
          <T project="enterprise-dashboard">{"Name"}</T>
          <AcuityTextFieldController
            placeholder={t("Organization Name", {}, { project: 'enterprise-dashboard' })}
            control={control}
            rules={{
              required: {
                value: true,
                message: t("Organization names must be longer than 2 characters", null, {
                  project: 'enterprise-dashboard' })

              },
              minLength: {
                value: 2,
                message: t("Organization names must be longer than 2 characters", null, {
                  project: 'enterprise-dashboard' })

              }
            }}
            name="orgName" />

        </Text.Body>
      </Box>
    </GenericModal>);

};
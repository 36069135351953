import * as Sentry from '@sentry/browser';
import { DatePicker, Wizard } from '@sqs/rosetta-compositions';
import { Checkbox, Divider } from '@sqs/rosetta-elements';
import { ArrowLeft, ArrowRight, Checkmark, ChevronLargeRight, Events } from '@sqs/rosetta-icons';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { ChangeEvent, useContext, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { listSchedulingInstances } from '../../apis/EnterpriseApiV2';
import { exportAppointments } from '../../apis/ExportApi';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { PageInfoQueryWithSearch } from '../../models/PaginatedResponse';
import { SearchSelectItem } from '../../models/SearchSelectItem';
import { selectEnterprise } from '../../stores/currentUser';
import { sendErrorMessage, sendSuccessMessage } from '../../stores/messages';
import { RootState } from '../../stores/rootReducer';
import { selectAll } from '../../stores/schedulingInstances';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { formatDateTime, t, T } from '../../i18n';
import { returnCheckedItemIds } from '../../utils/searchSelectUtils';
import { GenericModal } from '../common/GenericModal';
import { ModalTitle } from '../common/ModalTitle';
import { SearchAndSelect } from '../common/SearchAndSelect';
import { EditableDropdownDatepicker } from './ExportAppointmentsModal/EditableDropdownDatepicker';
import { isInSqspOrg } from '../../utils/sqspUserUtils';

interface ExportAppointmentsModalProps {
  readonly closeModal: () => void;
}

interface ExportAppointmentsModalForm {
  items: SearchSelectItem[];
  allItems: boolean;
  totalItems: number;
  startDate: string;
  endDate: string;
  includeCanceled: boolean;
}

export const ExportAppointmentsModal = ({ closeModal }: ExportAppointmentsModalProps) => {
  const { borders, space } = useTheme();
  const dispatch = useAppDispatch();
  const { isMobile } = usePlatformBreakpoint();

  const [currentStep, setCurrentStep] = useState<string>('step-one');
  const [history] = React.useState(() => new Wizard.History());
  const [isFirstDropdownOpen, setIsFirstDropdownOpen] = React.useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = React.useState(false);

  const { enterpriseId, currentUser, parsedSchedulingInstances, schedulingInstancesTotal } = useAppSelector(
    (state: RootState) => ({
      enterpriseId: selectEnterprise(state.currentUser)?.id,
      currentUser: state.currentUser.currentUser,
      parsedSchedulingInstances: selectAll(state.schedulingInstances).map((schedulingInstance) => ({
        itemId: schedulingInstance.id,
        name: schedulingInstance.instanceName,
        checked: false
      })),
      schedulingInstancesTotal: state.schedulingInstances?.pageInfo?.total || 0
    })
  );
  const email = isInSqspOrg(currentUser) ? currentUser.email : currentUser.username;

  const formMethods = useForm<ExportAppointmentsModalForm>({
    defaultValues: {
      items: [],
      allItems: false,
      totalItems: schedulingInstancesTotal,
      startDate: '',
      endDate: '',
      includeCanceled: false
    }
  });
  const { control, watch, setValue, getValues, register } = formMethods;

  const includeCancelledRegister = register('includeCanceled');

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const includeCanceled = watch('includeCanceled');
  const allItems = useWatch({ control, name: 'allItems' });

  const isNextDisabled = !(returnCheckedItemIds(useWatch({ control, name: 'items' })).length > 0 || allItems);

  const loadSchedulingInstances = async (eid: number, options?: PageInfoQueryWithSearch) => {
    const res = await listSchedulingInstances(eid, options);
    const instances = res.data.map((instance): SearchSelectItem => {
      return {
        itemId: instance.id,
        name: instance.instanceName,
        checked: false
      };
    });
    return {
      data: instances,
      pageInfo: res.pageInfo
    };
  };

  const nextStep = () => {
    setCurrentStep('step-two');
  };

  const previousStep = () => {
    setCurrentStep('step-one');
  };

  const triggerModalClose = () => {
    closeModal();
  };

  const tryExport = async () => {
    const formValues = getValues();
    try {
      if (!enterpriseId) {
        return;
      }
      const items = returnCheckedItemIds(formValues.items);
      await exportAppointments(
        enterpriseId,
        items,
        formValues.includeCanceled,
        new Date(formValues.startDate).getTime(),
        formValues.endDate ? new Date(formValues.endDate).getTime() : null
      );
      closeModal();
      dispatch(
        sendSuccessMessage(t("We will send an email to {email} when the export is complete.",


        { email }, {
          project: 'enterprise-dashboard' })

        )
      );
    } catch (err) {
      Sentry.captureEvent(err as Error);
      dispatch(
        sendErrorMessage(t("Failed to export appointments. Please try again.",
        null, {
          project: 'enterprise-dashboard' })

        )
      );
    }
  };

  const stepTitle = () => {
    return (
      <Box>
        <ModalTitle>
          <T project="enterprise-dashboard">{"Export Appointments"}</T>
        </ModalTitle>
        {!isMobile ?
        <>
            <Flex alignItems="center" gap={2}>
              <Flex alignItems="center" gap={2}>
                <Flex
                backgroundColor={currentStep === 'step-two' ? 'white' : 'green.400'}
                border={`${borders[2]}`}
                borderColor="green.400"
                width="32px"
                height="32px"
                borderRadius="32px"
                justifyContent="center"
                alignItems="center"
                color="white">

                  {currentStep === 'step-one' ? <span>1</span> : <Checkmark color="green.400" />}
                </Flex>

                <Text.Body color="black">
                  <T project="enterprise-dashboard">{"Select Scheduling Instances"}</T>
                </Text.Body>
              </Flex>
              <ChevronLargeRight color="gray.500" />
              <Flex alignItems="center" gap={2}>
                <Flex
                backgroundColor={currentStep === 'step-two' ? 'green.400' : 'gray.600'}
                border={`${borders[2]}`}
                borderColor={currentStep === 'step-two' ? 'green.400' : 'gray.600'}
                width="32px"
                height="32px"
                borderRadius="32px"
                justifyContent="center"
                alignItems="center"
                color="white">

                  <span>2</span>
                </Flex>
                <Text.Body color={currentStep === 'step-two' ? 'black' : 'gray.500'}>
                  <T project="enterprise-dashboard">{"Export options"}</T>
                </Text.Body>
              </Flex>
            </Flex>
            <Divider mt={4} mb={4} />
          </> :
        null}
      </Box>);

  };

  const modalActions = () => {
    if (currentStep === 'step-one') {
      return (
        <Flex justifyContent="space-between" pt={!isMobile ? 3 : 0}>
          <Button.Tertiary size="medium" type="button" onClick={() => triggerModalClose()}>
            <T project="enterprise-dashboard">{"Cancel"}</T>
          </Button.Tertiary>
          <Button.Primary size="medium" type="button" onClick={() => nextStep()} disabled={isNextDisabled}>
            <T project="enterprise-dashboard">{"Next"}</T>
            <ArrowRight css={{ marginLeft: '4px' }} color="white" />
          </Button.Primary>
        </Flex>);

    }
    return (
      <Flex justifyContent="space-between" pt={!isMobile ? 3 : 0}>
        <Button.Tertiary size="medium" type="button" onClick={() => triggerModalClose()}>
          <T project="enterprise-dashboard">{"Cancel"}</T>
        </Button.Tertiary>
        <Flex gap={4}>
          <Button.Secondary size="medium" type="button" onClick={() => previousStep()}>
            <ArrowLeft css={{ marginRight: '4px' }} />
            <T project="enterprise-dashboard">{"Back"}</T>
          </Button.Secondary>
          <Button.Primary size="medium" type="button" onClick={() => tryExport()} disabled={!startDate}>
            <T project="enterprise-dashboard">{"Export"}</T>
          </Button.Primary>
        </Flex>
      </Flex>);

  };

  // NOTE: this maxHeight calculation is a near estimate of the height of the rest of the values in the containing modal
  const containerMaxHeight = isMobile ? 'calc(100vh - 184px)' : '';
  return (
    <GenericModal width="602px" xPadding={space[9]} closeModal={closeModal} modalActions={modalActions()}>
      <FormProvider {...formMethods}>
        <Wizard currentStepId={currentStep} onRequestStep={setCurrentStep} history={history}>
          <Wizard.Step id="step-one" nextStepId="step-two" hideHeader hideNavigation>
            {stepTitle()}
            <SearchAndSelect
              allCheckText={t("All Instances", {}, { project: 'enterprise-dashboard' })}
              emptyText={t("No scheduling instances available", {}, { project: 'enterprise-dashboard' })}
              listItems={loadSchedulingInstances}
              searchPlaceholder={t("Search Scheduling Instances", {}, { project: 'enterprise-dashboard' })}
              showVariantSelector
              preloadedData={parsedSchedulingInstances}
              providedContainerMaxHeight={containerMaxHeight} />

          </Wizard.Step>
          <Wizard.Step id="step-two" hideHeader hideNavigation>
            {stepTitle()}
            <Flex gap={2}>
              <Flex width="50%" flexGrow="2" flexDirection="column">
                <Text.Body as="label">
                  <Text.Body fontWeight={500} mb={1} mt={0}>
                    <T project="enterprise-dashboard">{"Start Date"}</T>
                  </Text.Body>
                  <EditableDropdownDatepicker
                    exteriorPre={<Events />}
                    isDateValid={(value: string) => {
                      return !!value;
                    }}
                    isOpen={isFirstDropdownOpen}
                    onChange={(value: string) => {
                      const formattedDateTime = new Date(Date.parse(value)).toISOString();
                      setValue('startDate', formattedDateTime);
                    }}
                    onRequestClose={() => setIsFirstDropdownOpen(false)}
                    setIsOpen={setIsFirstDropdownOpen}
                    value={startDate ? formatDateTime(startDate, 'MM/DD/YYYY') : ''}
                    title={t("Start Date", {}, { project: 'enterprise-dashboard' })}>

                    <DatePicker
                      aria-label={t("date picker", {}, { project: 'enterprise-dashboard' })}
                      disable={endDate ? [{ after: endDate }] : []}
                      mode={DatePicker.MODE.RANGE_INCLUSIVE}
                      onChange={(value) => {
                        setValue('startDate', value?.[0]?.toString() || '');
                        setIsFirstDropdownOpen(false);
                      }}
                      rangePosition={DatePicker.RANGE_POSITION.FROM}
                      value={[startDate, endDate]}
                      valueType={DatePicker.VALUE_TYPE.ISO_8601} />

                  </EditableDropdownDatepicker>
                </Text.Body>
              </Flex>

              <Flex width="50%" flexGrow="2" flexDirection="column">
                <Text.Body as="label">
                  <Text.Body fontWeight={500} mb={1} mt={0}>
                    <T project="enterprise-dashboard">{"End Date"}</T>
                  </Text.Body>
                  <EditableDropdownDatepicker
                    exteriorPre={<Events />}
                    isDateValid={(value: string) => {
                      if (!value) {
                        return false;
                      }
                      if (startDate) {
                        return Date.parse(value) > Date.parse(startDate);
                      }
                      return true;
                    }}
                    isOpen={isSecondDropdownOpen}
                    onChange={(value: string) => {
                      const formattedDateTime = new Date(Date.parse(value)).toISOString();

                      setValue('endDate', formattedDateTime);
                    }}
                    onRequestClose={() => setIsSecondDropdownOpen(false)}
                    setIsOpen={setIsSecondDropdownOpen}
                    value={endDate ? formatDateTime(endDate, 'MM/DD/YYYY') : ''}
                    title={t("End Date", {}, { project: 'enterprise-dashboard' })}>

                    <DatePicker
                      aria-label={t("date picker", {}, { project: 'enterprise-dashboard' })}
                      disable={startDate ? [{ before: startDate }] : []}
                      mode={DatePicker.MODE.RANGE_INCLUSIVE}
                      onChange={(value) => {
                        setValue('endDate', value?.[1]?.toString() || '');
                        setIsSecondDropdownOpen(false);
                      }}
                      rangePosition={DatePicker.RANGE_POSITION.TO}
                      value={[startDate, endDate]}
                      valueType={DatePicker.VALUE_TYPE.ISO_8601} />

                  </EditableDropdownDatepicker>
                </Text.Body>
              </Flex>
            </Flex>
            <Flex mt={4}>
              <Text.Body
                as="label"
                css={{
                  display: 'inline-flex'
                }}>

                <Checkbox
                  checked={includeCanceled}
                  ref={includeCancelledRegister.ref}
                  onChange={async (v: boolean, e: ChangeEvent<HTMLInputElement>) => {
                    setValue('includeCanceled', v);
                    await includeCancelledRegister.onChange(e);
                  }}
                  value={true}
                  mr={4} />

                {t("Include cancelled appointments", {}, { project: 'enterprise-dashboard' })}
              </Text.Body>
            </Flex>
          </Wizard.Step>
        </Wizard>
      </FormProvider>
    </GenericModal>);

};
import * as Sentry from '@sentry/browser';
import { ApiError, getApiKey, updateApiKey } from '../../apis/EnterpriseApi';
import { ApiKey } from '../../models/ApiKey';
import { createSlice, PayloadAction, Reducer, SliceCaseReducers } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '../store';
import { AxiosError } from 'axios';
import { t } from '../../i18n';

export interface ApiKeyState {
  isLoading: boolean;
  apiKey: ApiKey | null;
  error: null | string | ApiError;
}

export const apiKeySlice = createSlice<ApiKeyState, SliceCaseReducers<ApiKeyState>, 'apiKey'>({
  name: 'apiKey',
  initialState: {
    isLoading: false,
    apiKey: null,
    error: null
  },
  reducers: {
    apiKeyRequested(state) {
      state.isLoading = true;
      state.error = null;
    },
    apiKeyReceived(state, { payload }: PayloadAction<ApiKey>) {
      state.apiKey = payload;
      state.isLoading = false;
      state.error = null;
    },
    errorReceived(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isLoading = false;
    },
    errorCleared(state) {
      state.error = null;
    },
    clearUnencryptedKey(state) {
      if (state.apiKey) {
        state.apiKey.unencryptedKey = undefined;
      }
    }
  }
});

export const { reducer: apiKeyReducer } = apiKeySlice;

const { apiKeyRequested, apiKeyReceived, errorReceived, errorCleared } = apiKeySlice.actions;

export { errorCleared };

const handleError = (error: AxiosError, dispatch: AppDispatch) => {
  if (!error.response) {
    return dispatch(
      errorReceived(t("Something went wrong. Please refresh and try again.",
      null, { project: 'enterprise-dashboard' })
      )
    );
  }
};

export const fetchApiKey =
(enterpriseId: number): AppThunk =>
async (dispatch) => {
  try {
    dispatch(apiKeyRequested(undefined));
    const apiKey = await getApiKey(enterpriseId);
    dispatch(apiKeyReceived(apiKey));
  } catch (e: any) {
    handleError(e as AxiosError, dispatch);
  }
};

export const resetApiKey =
(enterpriseId: number): AppThunk =>
async (dispatch) => {
  try {
    dispatch(apiKeyRequested(undefined));
    const apiKey = await updateApiKey(enterpriseId);
    dispatch(apiKeyReceived(apiKey));
  } catch (e: any) {
    handleError(e as AxiosError, dispatch);
  }
};
import * as Sentry from '@sentry/browser';
import { Grid } from '@sqs/rosetta-elements';
import { Flex } from '@sqs/rosetta-primitives';
import { ThemeContext } from '@sqs/rosetta-styled';
import { EnterpriseNavigation } from '@sqs/scheduling-enterprise-navigation';
import { enterpriseTheme } from '@sqs/scheduling-enterprise-theme';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import './App.less';
import { Outlet, useNavigate } from 'react-router-dom';
import { getUserFeatureFlags } from './apis/EnterpriseApi';
import { logoutUserInSqspEnterprise } from './apis/EnterpriseApiV2';
import { retrieveExport } from './apis/ExportApi';
import { AcuityLoader } from './components/common/AcuityLoader';
import { FeatureFlagProvider, FeatureFlags } from './components/FeatureFlagProvider';
import { NavBar } from './components/NavBar';
import { ToastManagerContext, ToastManagerProvider } from './components/ToastManagerProvider';
import { getFeatureFlags } from './const/feature-flags';
import { usePageCache } from './hooks/usePageCache';
import { t } from './i18n';
import { fetchCurrentUser } from './stores/currentUser';
import { clearErrorMessages, clearSuccessMessages, clearWarningMessages } from './stores/messages';
import { RootState } from './stores/rootReducer';
import { store, useAppDispatch, useAppSelector } from './stores/store';
import { logAmplitude } from './utils/amplitude';
import { isInSqspOrg } from './utils/sqspUserUtils';

function EnterpriseDashboard() {
  const dispatch = useAppDispatch();
  const toastManagerContext = useContext(ToastManagerContext);
  const { currentUser, errorMessages, successMessages, warningMessages, applicationSettings } = useAppSelector(
    (state: RootState) => ({
      isLoading: state.currentUser.isLoading,
      currentUser: state.currentUser.currentUser,
      errorMessages: state.messages.errorMessages,
      successMessages: state.messages.successMessages,
      warningMessages: state.messages.warningMessages,
      applicationSettings: state.applicationSettings
    })
  );
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(null);
  const navigate = useNavigate();
  const { disableGlobalLoaderBfcacheEvent, removeDisableGlobalLoaderBfcacheEvent } = usePageCache();

  // if the pathname is not `/` then include it
  const acuityBaseUrl = `${window.location.origin}${window.location.pathname.length > 1 ? window.location.pathname : ''}`;

  useEffect(() => {
    dispatch(fetchCurrentUser());
    const staticFlagList = getFeatureFlags();
    if (staticFlagList.length > 0) {
      getUserFeatureFlags(staticFlagList).then(setFeatureFlags);
    } else {
      setFeatureFlags({});
    }
    logAmplitude('User Views Enterprise Dashboard');

    disableGlobalLoaderBfcacheEvent();

    return () => {
      removeDisableGlobalLoaderBfcacheEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentUser || !currentUser.enterprise) {
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const exportId = queryParams.get('exportId');
    if (exportId === null || queryParams.get('download') !== 'true') {
      return;
    }

    retrieveExport(Number(currentUser.enterprise.id), Number(exportId)).
    then(({ id, exportType, link }) => {
      if (!link) {
        throw new Error('CSV does not have a filename or link');
      }

      const anchor = document.createElement('a');
      anchor.setAttribute('href', link);
      anchor.setAttribute('download', `${exportType}-export-${id}.csv`);
      anchor.click();

      // Remove query string from URL
      window.history.replaceState(null, '', window.location.href.split('?')[0]);
    }).
    catch((error) => {
      Sentry.captureEvent(error as Error);
      return toastManagerContext?.show({
        content: t("Unable to download CSV. Please try again.", null, { project: 'enterprise-dashboard' }),
        variant: 'error'
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (toastManagerContext) {
      if (errorMessages.length) {
        errorMessages.forEach((msg: string) => {
          toastManagerContext.show({
            content: msg,
            variant: 'error'
          });
        });
        dispatch(clearErrorMessages());
      }

      if (successMessages.length) {
        successMessages.forEach((msg: string) => {
          toastManagerContext.show({
            content: msg,
            variant: 'success'
          });
        });
        dispatch(clearSuccessMessages());
      }

      if (warningMessages.length) {
        warningMessages.forEach((msg: string) => {
          toastManagerContext.show({
            content: msg,
            variant: 'warning'
          });
        });
        dispatch(clearWarningMessages());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessages, successMessages, warningMessages]);

  if (currentUser === null || featureFlags === null || applicationSettings.globalLoader) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <AcuityLoader />
      </Flex>);

  }

  return (
    <FeatureFlagProvider featureFlags={featureFlags}>
      {isInSqspOrg(currentUser) ?
      <EnterpriseNavigation
        currentUser={currentUser}
        configuration={{
          dashboardBaseUrl: acuityBaseUrl,
          acuityBaseUrl: __SCHEDULING_HOST__,
          navigationFunction: navigate,
          squarespaceAccountBaseUrl: __SQSP_ACCOUNT_URL__,
          logout: logoutUserInSqspEnterprise
        }} /> :


      <NavBar currentUser={currentUser} enterprise={currentUser.enterprise} />
      }
      <Grid.Container alignItems="center" gridConstraint={[12, 12, 12, 12, 11, 10]}>
        <Grid.Item id={'container-content'} m={'auto'} p={0}>
          <Outlet />
        </Grid.Item>
      </Grid.Container>
    </FeatureFlagProvider>);

}

export default function App(): ReactElement {
  return (
    <Provider store={store}>
      <ThemeContext.Provider theme={enterpriseTheme}>
        <ToastManagerProvider>
          <EnterpriseDashboard />
        </ToastManagerProvider>
      </ThemeContext.Provider>
    </Provider>);

}
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import {
  ApiError,
  getCurrentUser,
  updateCurrentUser as apiUpdateCurrentUser,
  UpdatePassword,
  UpdateUsername } from
'../../apis/EnterpriseApi';
import { EnterpriseUser } from '../../models/EnterpriseUser';
import { t } from '../../i18n';
import { sendSuccessMessage } from '../messages';
import { AppDispatch, AppThunk } from '../store';
import { SqspUser } from '../../models/SqspUser';

export interface CurrentUserState {
  isLoading: boolean;
  currentUser: EnterpriseUser | SqspUser | null;
  error: null | string | ApiError;
}

export const currentUserSlice = createSlice<CurrentUserState, SliceCaseReducers<CurrentUserState>, 'currentUser'>({
  name: 'currentUser',
  initialState: {
    isLoading: true,
    currentUser: null,
    error: null
  },
  reducers: {
    currentUserRequested(state) {
      state.isLoading = true;
      state.error = null;
    },
    currentUserReceived(state, { payload }: PayloadAction<EnterpriseUser | SqspUser>) {
      state.currentUser = payload;
      state.isLoading = false;
      state.error = null;
    },
    errorReceived(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    errorCleared(state) {
      state.error = null;
    }
  }
});

export const { reducer: currentUserReducer } = currentUserSlice;

const { currentUserRequested, currentUserReceived, errorReceived, errorCleared } = currentUserSlice.actions;

export { errorCleared };

const handleError = (error: AxiosError, dispatch: AppDispatch) => {
  if (!error.response) {
    return dispatch(
      errorReceived(t("Something went wrong. Please refresh and try again.",
      null, { project: 'enterprise-dashboard' })
      )
    );
  }

  Sentry.captureEvent(error as Error);

  const { status, data } = error.response;
  if (status === 401) {
    window.location.href = `${__SCHEDULING_HOST__}/login.php`;
    return;
  }

  if (status === 403) {
    window.location.href = `${__SCHEDULING_HOST__}/`;
    return;
  }

  // TODO: this is likely not the right casting for data
  dispatch(errorReceived((data as any).error));
};

export const fetchCurrentUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(currentUserRequested(undefined));

    const currentUser = await getCurrentUser();

    dispatch(currentUserReceived(currentUser));
  } catch (e: any) {
    handleError(e as AxiosError, dispatch);
  }
};

export const updateCurrentUser = (userData: UpdateUsername | UpdatePassword, successMessage?: string): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(currentUserRequested(undefined));

      const user = await apiUpdateCurrentUser(userData);

      dispatch(currentUserReceived(user));

      if (successMessage) {
        dispatch(sendSuccessMessage(successMessage));
      } else {
        dispatch(sendSuccessMessage(t("User was successfully updated", {}, { project: 'enterprise-dashboard' })));
      }
      return true;
    } catch (e: any) {
      handleError(e as AxiosError, dispatch);
      throw e;
    }
  };
};

// Selectors
export const selectEnterprise = ({ currentUser }: CurrentUserState) => {
  if (!currentUser || !currentUser.enterprise) {
    return null;
  }

  return currentUser.enterprise;
};
import { ExclamationMarkCircle } from '@sqs/rosetta-icons';
import { Button, Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { removeUserFromInstance } from '../../apis/EnterpriseApiV2';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { selectEnterprise } from '../../stores/currentUser';
import { sendErrorMessage, sendSuccessMessage } from '../../stores/messages';
import { RootState } from '../../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { t, T } from '../../i18n';
import { GenericModal } from '../common/GenericModal';
import { filterSingleUser } from '../../stores/orgUsers';
import { SqspUser } from '../../models/SqspUser';
import { fetchSchedulingInstances } from '../../stores/schedulingInstances';
import { ModalTitle } from '../common/ModalTitle';

interface UserRemoveUserFromSchedulingInstanceModalProps {
  readonly orgUserId: number;
  readonly instanceId: number;
  readonly closeModal: () => void;
  readonly refresh?: () => void;
}

export const UserRemoveUserFromSchedulingInstanceModal = ({
  orgUserId,
  instanceId,
  closeModal,
  refresh
}: UserRemoveUserFromSchedulingInstanceModalProps) => {
  const dispatch = useAppDispatch();
  const { isMobile } = usePlatformBreakpoint();

  const { enterpriseId, orgUser } = useAppSelector((state: RootState) => {
    return {
      currentUser: state.currentUser.currentUser as SqspUser,
      enterpriseId: selectEnterprise(state.currentUser)?.id,
      orgUser: filterSingleUser(state, orgUserId)
    };
  });

  const onClick = () => {
    const removeError = () =>
    dispatch(
      sendErrorMessage(t("There was a problem removing the user from the instance",
      null, { project: 'enterprise-dashboard' })
      )
    );
    if (enterpriseId && instanceId && orgUser) {
      removeUserFromInstance(enterpriseId, instanceId, orgUserId).
      then(() => {
        dispatch(
          sendSuccessMessage(t("Scheduling Instance was removed from user access.",
          null, { project: 'enterprise-dashboard' })
          )
        );
      }).
      catch(() => {
        removeError();
      }).
      finally(() => {
        closeModal();
        dispatch(fetchSchedulingInstances(enterpriseId));
        if (refresh) {
          refresh();
        }
      });
    } else {
      removeError();
      closeModal();
    }
  };

  const triggerCloseModal = () => {
    closeModal();
  };

  return (
    <GenericModal
      closeModal={triggerCloseModal}
      modalActions={
      <Flex justifyContent="space-between" mt={isMobile ? 0 : 6}>
          <Button.Tertiary type="button" onClick={triggerCloseModal}>
            <T project="enterprise-dashboard">{"Cancel"}</T>
          </Button.Tertiary>
          <Button.Tertiary type="button" onClick={onClick} color="red.400">
            <T project="enterprise-dashboard">{"Remove user"}</T>
          </Button.Tertiary>
        </Flex>
      }>

      <ModalTitle>
        <ExclamationMarkCircle color="red.400" sx={{ marginBottom: '-4px' }} />
        &nbsp;<T project="enterprise-dashboard">{"Remove user access from this Scheduling Instance?"}</T>
      </ModalTitle>
      <Text.Body>
        <T project="enterprise-dashboard">{"This user will lose access to the Scheduling Instance immediately."}</T>
      </Text.Body>
    </GenericModal>);

};
import { createEntityAdapter, createSlice, EntityState, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { createUser, UserData } from '../../apis/EnterpriseApi';
import {
  createSqspSchedulingInstance,
  deleteSqspSchedulingInstance,
  listSchedulingInstances } from
'../../apis/EnterpriseApiV2';
import { INITIAL_LIMIT, PAGINATION_LIMIT_MIN } from '../../const/pagination';
import { PageInfo, PageInfoQueryWithSearch } from '../../models/PaginatedResponse';
import { SchedulingInstance, SchedulingInstanceData } from '../../models/SchedulingInstance';
import { t } from '../../i18n';
import { reduxErrorHandler } from '../../utils/reduxError';
import { sendSuccessMessage } from '../messages';
import { AppThunk } from '../store';

export type SchedulingInstancesState = {
  isLoading: boolean;
  error: null | string | Record<string, string>;
  pageInfo: PageInfo;
} & EntityState<SchedulingInstance>;

const schedulingInstancesAdapter = createEntityAdapter<SchedulingInstance>();

export const schedulingInstancesSlice = createSlice<
  SchedulingInstancesState,
  SliceCaseReducers<SchedulingInstancesState>,
  'schedulingInstances'>(
  {
    name: 'schedulingInstances',
    initialState: schedulingInstancesAdapter.getInitialState({
      isLoading: true,
      error: null,
      pageInfo: {
        total: 0,
        limit: PAGINATION_LIMIT_MIN,
        offset: 0
      }
    }),
    reducers: {
      schedulingInstanceRequested(state) {
        state.isLoading = true;
        state.error = null;
      },
      schedulingInstancesRequested(state) {
        state.isLoading = true;
        state.error = null;
      },
      schedulingInstanceReceived(state, action: PayloadAction<SchedulingInstance>) {
        schedulingInstancesAdapter.addOne(state, action.payload);
        state.isLoading = false;
        state.error = null;
      },
      schedulingInstancesReceived(state, action: PayloadAction<SchedulingInstance[]>) {
        schedulingInstancesAdapter.setAll(state, action.payload);
        state.isLoading = false;
        state.error = null;
      },
      appendSchedulingInstances(state, action: PayloadAction<SchedulingInstance[]>) {
        schedulingInstancesAdapter.upsertMany(state, action.payload);
        state.isLoading = false;
        state.error = null;
      },
      errorReceived(state, action: PayloadAction<string | Record<string, string>>) {
        state.error = action.payload;
        state.isLoading = false;
      },
      updatePageInfo(state, action: PayloadAction<PageInfo>) {
        state.pageInfo = action.payload;
        state.isLoading = false;
      }
    }
  });

export const { reducer: schedulingInstancesReducer } = schedulingInstancesSlice;

// Selectors
export const { selectAll, selectById, selectTotal } = schedulingInstancesAdapter.getSelectors();

export const {
  schedulingInstancesRequested,
  schedulingInstanceRequested,
  schedulingInstancesReceived,
  appendSchedulingInstances,
  schedulingInstanceReceived,
  errorReceived,
  updatePageInfo
} = schedulingInstancesSlice.actions;

// Async actions
/**
 * Will fetch scheduling instances and APPEND to the current data store of scheduling instances
 * @param {number} enterpriseId
 * @param {Partial<PageInfo>} options
 * @returns {AppThunk}
 */
export const fetchBackgroundSchedulingInstances = (enterpriseId: number, options?: Partial<PageInfo>): AppThunk => {
  return async (dispatch) => {
    try {
      // only fetch if offset is provided and is at least greater than INITIAL_LIMIT
      if (options?.offset && options.offset >= INITIAL_LIMIT) {
        const schedulingInstancesResponse = await listSchedulingInstances(enterpriseId, options);

        dispatch(appendSchedulingInstances(schedulingInstancesResponse.data));
        dispatch(updatePageInfo(schedulingInstancesResponse.pageInfo));

        if (options.offset + schedulingInstancesResponse.data.length < schedulingInstancesResponse.pageInfo.total) {
          dispatch(
            fetchBackgroundSchedulingInstances(enterpriseId, {
              ...options,
              offset: options.offset + schedulingInstancesResponse.data.length
            })
          );
        }
      }
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("Not authorized",
        null, { project: 'enterprise-dashboard' })
      );
    }
  };
};

/**
 * Initial fetch for listing scheduling instances
 * @param {number} enterpriseId
 * @param {Partial<PageInfoQueryWithSearch>} options
 * @returns {AppThunk}
 */
export const fetchSchedulingInstances = (
enterpriseId: number,
options?: Partial<PageInfoQueryWithSearch>)
: AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(schedulingInstancesRequested(undefined));
      const schedulingInstancesResponse = await listSchedulingInstances(enterpriseId, options);

      dispatch(schedulingInstancesReceived(schedulingInstancesResponse.data));
      dispatch(updatePageInfo(schedulingInstancesResponse.pageInfo));

      if (schedulingInstancesResponse.data.length < schedulingInstancesResponse.pageInfo.total) {
        dispatch(
          fetchBackgroundSchedulingInstances(enterpriseId, {
            ...options,
            offset: schedulingInstancesResponse.data.length
          })
        );
      }
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("Not authorized",
        null, { project: 'enterprise-dashboard' })
      );
    }
  };
};

export const createSchedulingInstance = (enterpriseId: number, userData: UserData): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(schedulingInstanceRequested(undefined));

      const schedulingInstance = await createUser(enterpriseId, userData);

      dispatch(schedulingInstanceReceived(schedulingInstance));
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("Not authorized to create a scheduling instance",
        null, { project: 'enterprise-dashboard' })
      );
    }
  };
};

export const createSqspSchedulingInstanceAction = (
enterpriseId: number,
schedulingInstanceData: SchedulingInstanceData,
pageInfo: PageInfo)
: AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(schedulingInstanceRequested(undefined));

      const schedulingInstanceResponse = await createSqspSchedulingInstance(enterpriseId, schedulingInstanceData);

      dispatch(
        sendSuccessMessage(t("{instanceName} has been created.",


        { instanceName: schedulingInstanceResponse.data.instanceName }, {
          project: 'enterprise-dashboard' })

        )
      );
      dispatch(schedulingInstanceReceived(schedulingInstanceResponse.data));
      dispatch(updatePageInfo({ ...pageInfo, total: pageInfo.total + 1 }));
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("Not authorized to create a user",
        null, { project: 'enterprise-dashboard' })
      );
    }
  };
};

export const deleteSqspSchedulingInstanceAction = (
enterpriseId: number,
schedulingInstanceData: Partial<SchedulingInstance>)
: AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(schedulingInstanceRequested(undefined));
      await deleteSqspSchedulingInstance(enterpriseId, schedulingInstanceData.id || 0);

      dispatch(
        sendSuccessMessage(t("{instanceName} has been deleted.",


        { instanceName: schedulingInstanceData.instanceName }, {
          project: 'enterprise-dashboard' })

        )
      );
      dispatch(fetchSchedulingInstances(enterpriseId));
    } catch (e: any) {
      return reduxErrorHandler(
        e,
        dispatch,
        errorReceived, t("There was an error attempting to delete {instanceName}",


        { instanceName: schedulingInstanceData.instanceName }, {
          project: 'enterprise-dashboard' })

      );
    }
  };
};
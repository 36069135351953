import { fetchOrGenerateUserPermissionsForSchedulingInstance } from '../apis/EnterpriseApiV2';
import { t } from '../i18n';
import { SchedulingInstance } from '../models/SchedulingInstance';
import { disableGlobalLoader, enableGlobalLoader } from '../stores/applicationSettings';
import { selectEnterprise } from '../stores/currentUser';
import { sendErrorMessage } from '../stores/messages';
import { RootState } from '../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../stores/store';

export const useSchedulingInstanceActions = () => {
  const dispatch = useAppDispatch();
  const { enterpriseId } = useAppSelector((state: RootState) => ({
    enterpriseId: selectEnterprise(state.currentUser)?.id
  }));

  const viewSchedulingInstanceAsUser = async (schedulingInstance: SchedulingInstance): Promise<void> => {
    if (!enterpriseId) {
      return Promise.reject();
    }
    try {
      dispatch(enableGlobalLoader());
      await fetchOrGenerateUserPermissionsForSchedulingInstance(enterpriseId, schedulingInstance.id);
      if (schedulingInstance.adminUrl) {
        window.location.href = schedulingInstance.adminUrl;
      }
    } catch (e) {
      dispatch(
        sendErrorMessage(t("There was an error navigating to the instance. Please try again.",
        null, {
          project: 'enterprise-dashboard' })

        )
      );
      dispatch(disableGlobalLoader());
    }
  };

  return { viewSchedulingInstanceAsUser };
};
import React, { useState } from 'react';
import { Cell, TextInput } from '@sqs/rosetta-elements';
import { DatePicker, Dropdown } from '@sqs/rosetta-compositions';
import { Empty, UnixFrom, UnixRange } from '@sqs/rosetta-compositions/build/lib/components/DatePicker/DatePicker';
import { Events } from '@sqs/rosetta-icons';
import { TextInputProps } from '@sqs/rosetta-elements/build/lib/components/TextInput/TextInput';
import { formatDateTime, t } from '../../i18n';
import noop from 'lodash/noop';
import { DropdownProps } from '@sqs/rosetta-compositions/build/lib/components/Dropdown/components/Dropdown';

interface TextInputCellProps {
  readonly setIsOpen: (open: boolean) => void;
}

const TextInputCell = React.forwardRef<HTMLElement, TextInputCellProps & TextInputProps>(
  ({ onChange, setIsOpen, value, sx, ...rest }, ref) =>
  <Cell
    as="label"
    ref={ref}
    body={
    <TextInput
      onChange={onChange}
      onFocus={() => setIsOpen(true)}
      textAlign="right"
      placeholder={t("MM/DD/YYYY", null, { project: 'enterprise-dashboard' })}
      value={value} />

    }
    {...rest} />


);

interface EditableDropdownProps {
  readonly children: React.ReactNode | React.ReactNode[];
  readonly onRequestClose: (open: boolean) => void;
  readonly onBlur?: () => void;
}

const EditableDropdown = React.forwardRef<HTMLElement, EditableDropdownProps>(
  ({ children, onBlur, onRequestClose, ...rest }, ref) => {
    return (
      <Dropdown
        {...rest}
        ref={ref}
        renderDisplay={TextInputCell}
        renderDropdown={(props: DropdownProps) =>
        <Dropdown.Dropdown
          {...props}
          onBlur={() => {
            onRequestClose(false);
            if (onBlur) {
              onBlur();
            }
          }}
          placement="bottom"
          px={2} />

        }>

        {children}
      </Dropdown>);

  }
);

interface EditableDropdownGroupProps {
  readonly openDropdownIndex: number | null;
  readonly setOpenDropdownIndex: (index: number | null) => void;
  readonly value: Empty | UnixFrom | UnixRange;

  readonly children?: React.ReactNode | React.ReactNode[];
  readonly onBlur?: () => void;
}

export function EditableDropdownGroup({
  children,
  onBlur,
  openDropdownIndex,
  setOpenDropdownIndex,
  value
}: EditableDropdownGroupProps) {
  const dropdownAnchorRef = React.useRef<HTMLElement>(null);

  return (
    <>
      <EditableDropdown
        ref={dropdownAnchorRef}
        // @ts-expect-error
        exteriorPre={<Events />}
        interiorPre={t("Start date", null, { project: 'enterprise-dashboard' })}
        isOpen={openDropdownIndex === 0}
        onBlur={onBlur}
        onRequestClose={() => setOpenDropdownIndex(null)}
        setIsOpen={(open: boolean) => open && setOpenDropdownIndex(0)}
        value={value[0] ? formatDateTime(value[0], 'L') : ''}>

        {children}
      </EditableDropdown>
      <EditableDropdown
        ref={dropdownAnchorRef}
        // @ts-expect-error
        exteriorPre={<Events />}
        interiorPre={t("End date", null, { project: 'enterprise-dashboard' })}
        isOpen={openDropdownIndex === 1}
        onBlur={onBlur}
        onRequestClose={() => setOpenDropdownIndex(null)}
        setIsOpen={(open: boolean) => open && setOpenDropdownIndex(1)}
        value={value[1] ? formatDateTime(value[1], 'L') : ''}>

        {children}
      </EditableDropdown>
    </>);

}

interface DateRangePickerProps {
  readonly onChange: (value: Empty | UnixFrom | UnixRange) => void;
  readonly value: Empty | UnixFrom | UnixRange;

  readonly onBlur?: () => void;
}

export function DateRangePicker({ onBlur, onChange, value }: DateRangePickerProps) {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);

  return (
    <EditableDropdownGroup
      onBlur={onBlur || noop}
      openDropdownIndex={openDropdownIndex}
      setOpenDropdownIndex={setOpenDropdownIndex}
      value={value}>

      <DatePicker
        mode={DatePicker.MODE.RANGE}
        onChange={(dateRange: Empty | UnixFrom | UnixRange) => {
          if (openDropdownIndex === 0) {
            setOpenDropdownIndex(1);
          } else if (openDropdownIndex === 1 && dateRange[1]) {
            setOpenDropdownIndex(null);
          }

          onChange(dateRange);
        }}
        value={value} />

    </EditableDropdownGroup>);

}
import { Dropdown } from '@sqs/rosetta-compositions';
import { Textarea } from '@sqs/rosetta-elements';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React from 'react';
import { useForm } from 'react-hook-form';
import { submitSsoCredentials } from '../../../apis/EnterpriseApiV2';
import { t, T } from '../../../i18n';
import { selectEnterprise } from '../../../stores/currentUser';
import { RootState } from '../../../stores/rootReducer';
import { useAppSelector } from '../../../stores/store';
import { AcuityTextFieldController } from '../../common/AcuityTextFieldController';
import { GenericModal } from '../../common/GenericModal';
import { ModalTitle } from '../../common/ModalTitle';

interface EnterpriseSsoAddModalProps {
  readonly closeModal: () => void;
}

interface SsoProvider {
  id: string;
  providerName: string;
  ssoUrlDescription?: string;
  hardcodedSsoUrl?: string;
  isX509?: boolean;
  ssoUrlRule: RegExp; // TODO: This may not be necessary based on how we end up handling errors (waiting on Error Messages ticket in https://squarespace.atlassian.net/browse/AE-434)
}

const SSO_PROVIDERS: SsoProvider[] = [
{
  id: 'OKTA',
  providerName: 'Okta',
  ssoUrlDescription: t("Must be formatted as: https://{YOUR_OKTA_DOMAIN}.okta.com/oauth2",

  {}, {
    project: 'enterprise-dashboard' }),

  ssoUrlRule: new RegExp('^https://([a-z0-9-_]*).okta.com/oauth2$', 'i')
},
{
  id: 'MICROSOFT',
  providerName: 'Microsoft',
  ssoUrlDescription: t("Must be formatted as: https://sts.windows.net/{YOUR_TENANT_ID}",

  {}, {
    project: 'enterprise-dashboard' }),

  ssoUrlRule: new RegExp('^https://sts.windows.net/([a-z0-9-_]*)$', 'i')
},
{
  id: 'CLASSLINK',
  providerName: 'Classlink',
  hardcodedSsoUrl: 'https://launchpad.classlink.com',
  ssoUrlRule: new RegExp('https://launchpad.classlink.com', 'i')
},
{
  id: 'GOOGLE',
  providerName: 'Google',
  ssoUrlDescription: t("Must be formatted as: https://accounts.google.com/o/saml2/idp?idpid={YOUR_IDPID}",

  {}, {
    project: 'enterprise-dashboard' }),

  isX509: true,
  ssoUrlRule: new RegExp('^https://accounts.google.com/o/saml2/idp?idpid=([a-z0-9-_]*)$', 'i')
}];


interface SsoForm {
  clientId?: string;
  clientSecret?: string;
  provider: SsoProvider | null;
  ssoUrl: string;
  x509Certificate?: string;
}

export const EnterpriseSsoAddModal = ({ closeModal }: EnterpriseSsoAddModalProps) => {
  const { colors } = useTheme();

  const { enterpriseId } = useAppSelector((state: RootState) => ({
    enterpriseId: selectEnterprise(state.currentUser)?.id
  }));

  const {
    control,
    clearErrors,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch
  } = useForm<SsoForm>({
    defaultValues: {
      clientId: '',
      clientSecret: '',
      provider: null,
      ssoUrl: '',
      x509Certificate: ''
    }
  });
  const selectedProvider = watch('provider');

  const close = () => {
    closeModal();
    reset();
  };

  const submitForm = handleSubmit(async (formData) => {
    if (enterpriseId && selectedProvider) {
      // TODO: implement loading state, error & success message (https://squarespace.atlassian.net/browse/AE-434)
      await submitSsoCredentials(enterpriseId, { ...formData, provider: selectedProvider.id });
      close();
    }
  });

  const createSsoForm = () => {
    if (selectedProvider) {
      if (selectedProvider.hardcodedSsoUrl) {
        setValue('ssoUrl', selectedProvider.hardcodedSsoUrl);
      }
      const ssoUrlElement =
      <Box mb={5}>
          <Text.Label htmlFor="ssoUrl">
            <T project="enterprise-dashboard">{"SSO URL"}</T>
          </Text.Label>
          <Text.Caption m={0} display="block">
            {selectedProvider.ssoUrlDescription}
          </Text.Caption>
          <AcuityTextFieldController
          id="ssoUrl"
          control={control}
          name={'ssoUrl'}
          isDisabled={Boolean(selectedProvider.hardcodedSsoUrl)}
          rules={{
            required: t("SSO URL is required", {}, { project: 'enterprise-dashboard' })
            // TODO: commenting out until we know what errors we want to handle (https://squarespace.atlassian.net/browse/AE-434)
            // pattern: {
            //   value: selectedProvider.ssoUrlRule,
            //   message: t('Your SSO URL must match the pattern above', {}, { project: 'enterprise-dashboard' }),
            // },
          }} />

        </Box>;


      if (selectedProvider.isX509) {
        const x509CertificateRegister = register('x509Certificate', {
          required: t("Your X.509 Certificate is required", {}, { project: 'enterprise-dashboard' })
        });
        return (
          <Box mb={3}>
            <Text.Label htmlFor="x509certificate">
              <T project="enterprise-dashboard">{"X.509 Certificate"}</T>
            </Text.Label>
            <Text.Caption m={0} display="block">
              <T project="enterprise-dashboard">{"The certificate issued by Google."}</T>
            </Text.Caption>

            <Textarea
              id="x509certificate"
              ref={x509CertificateRegister.ref}
              mt={1}
              mb={3}
              border={1}
              borderColor={colors.gray[700]}
              borderRadius={1}
              width="calc(100% - 20px)"
              height="45px"
              padding="10px"
              css={{
                display: 'block',
                '&:active, &:focus': {
                  borderColor: 'black'
                }
              }}
              onBlur={x509CertificateRegister.onBlur}
              name={x509CertificateRegister.name}
              onChange={async (v: string, e: Event) => {
                await x509CertificateRegister.onChange(e);
                clearErrors('x509Certificate');
              }} />


            {errors.x509Certificate ?
            <Text.Caption color="red.300">{errors.x509Certificate.message}</Text.Caption> :
            null}
            {ssoUrlElement}
          </Box>);

      }

      return (
        <>
          <Box mb={3}>
            <Text.Label htmlFor="clientId">
              <T project="enterprise-dashboard">{"Client ID"}</T>
            </Text.Label>
            <Text.Caption mb={0} display="block" textTransform="none">
              <T project="enterprise-dashboard">{"The OAuth client ID issued by the provider."}</T>
            </Text.Caption>
            <AcuityTextFieldController
              control={control}
              name={'clientId'}
              id="clientId"
              rules={{
                required: t("Client ID is required", {}, { project: 'enterprise-dashboard' })
              }} />

          </Box>

          <Box mb={3}>
            <Text.Label htmlFor="clientSecret">
              <T project="enterprise-dashboard">{"Client Secret"}</T>
            </Text.Label>
            <Text.Caption m={0} display="block">
              <T project="enterprise-dashboard">{"The OAuth client ID issued by the provider."}</T>
            </Text.Caption>
            <AcuityTextFieldController
              control={control}
              id="clientSecret"
              name={'clientSecret'}
              rules={{
                required: t("Client Secret is required", {}, { project: 'enterprise-dashboard' })
              }} />

          </Box>

          {ssoUrlElement}
        </>);

    }
    return <></>;
  };

  return (
    <GenericModal
      closeModal={close}
      modalActions={
      <Flex justifyContent="space-between">
          <Button.Tertiary size="medium" type="button" onClick={close}>
            <T project="enterprise-dashboard">{"Cancel"}</T>
          </Button.Tertiary>
          <Button.Primary size="medium" type="button" onClick={submitForm} disabled={!selectedProvider}>
            <T project="enterprise-dashboard">{"Save"}</T>
          </Button.Primary>
        </Flex>
      }>

      <ModalTitle>
        <T project="enterprise-dashboard">{"Set up SSO"}</T>
      </ModalTitle>

      <Text.Label htmlFor="sso-provider" id="sso-provider-label">
        <T project="enterprise-dashboard">{"Provider"}</T>
      </Text.Label>
      <Dropdown
        aria-label="sso-provider-label"
        id="sso-provider"
        placement="bottom"
        onChange={(v: SsoProvider) => {
          reset();
          setValue('provider', v, { shouldDirty: true });
        }}
        value={selectedProvider}>

        {SSO_PROVIDERS.map((ssoProvider) =>
        <Dropdown.Option key={ssoProvider.id} value={ssoProvider}>
            {ssoProvider.providerName}
          </Dropdown.Option>
        )}
      </Dropdown>
      <Box mt={4}>{createSsoForm()}</Box>
      <>{!selectedProvider && <Box mb={5} />}</>
    </GenericModal>);

};